.c-form-field {
    $c: &;

    &__label {
        &.is-required {
            &:after {
                @apply .text-midc-primary;
            }
        }
    }
}
