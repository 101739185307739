.c-slider {
    $c: &;

    &__nav-button {
        &:hover,
        &:active {
            @apply .bg-midc-primary .border-midc-primary;
        }

        &--alt {
            .c-icon {
                @apply .text-midc-primary;
            }
        }
    }
}
