.c-hero {
    $c: &;

    @apply .bg-secondary;

    &--alt {
        #{$c} {
            &__bottom {
                @apply .bg-secondary;
            }
        }
    }
}
