/* purgecss start ignore */
::selection {
  @apply .bg-midc-primary;
}

.h0 strong,
h1 strong,
h2 strong,
h3 strong,
h4 strong,
h5 strong,
h6 strong {
  @apply .text-midc-primary;
}

.c-button--primary {
  @apply .bg-midc-primary;
}

.c-button--secondary {
  @apply .border-midc-primary;
}

.c-cta {
  @apply .bg-midc-primary;
}

.c-form-field__label.is-required:after {
  @apply .text-midc-primary;
}

.c-hero {
  @apply .bg-secondary;
}

.c-hero--alt .c-hero__bottom {
  @apply .bg-secondary;
}

.c-link--primary:after {
  @apply .bg-midc-primary;
}

.c-link--secondary .c-link__label,
.c-link--secondary .c-link__icon .c-icon {
  @apply .text-midc-primary;
}

.c-link--tertiary:hover, .c-link--tertiary:active, .c-link--tertiary.hover {
  @apply .border-midc-primary;
}

.c-nav--primary .c-nav__item:after {
  @apply .bg-midc-primary;
}

@screen lg {
  .c-nav--primary .c-nav__item-inner:hover .c-nav__label-text,
  .c-nav--primary .c-nav__item-inner:hover .c-nav__label-icon .c-icon, .c-nav--primary .c-nav__item-inner:active .c-nav__label-text,
  .c-nav--primary .c-nav__item-inner:active .c-nav__label-icon .c-icon, .c-nav--primary .c-nav__item-inner.has-hover .c-nav__label-text,
  .c-nav--primary .c-nav__item-inner.has-hover .c-nav__label-icon .c-icon {
    @apply .text-midc-primary;
  }
}

@screen lg {
  .c-nav--primary .c-nav__sub-link:after {
    @apply .bg-midc-primary;
  }
  .c-nav--primary .c-nav__sub-link:hover .c-nav__sub-label, .c-nav--primary .c-nav__sub-link:active .c-nav__sub-label {
    @apply .text-midc-primary;
  }
}

.c-nav--breadcrumb .c-nav__link:hover .c-nav__label-text, .c-nav--breadcrumb .c-nav__link:active .c-nav__label-text {
  @apply .text-midc-primary;
}

.c-partner:hover:after, .c-partner:active:after, .c-partner.is-active:after {
  @apply .border-midc-primary;
}

.c-presentation__footer:before {
  @apply .bg-midc-primary;
}

.c-presentation:after {
  @apply .bg-midc-primary;
}

.c-presentation.has-image .c-presentation__picture:after {
  @apply .bg-secondary;
}

.c-project:after {
  @apply .bg-midc-primary;
}

.c-slider__nav-button:hover, .c-slider__nav-button:active {
  @apply .bg-midc-primary .border-midc-primary;
}

.c-slider__nav-button--alt .c-icon {
  @apply .text-midc-primary;
}

.c-text a:after {
  @apply .bg-midc-primary;
}

.c-text ul li:before {
  @apply .bg-midc-primary;
}

.c-text ol li::marker {
  @apply .text-midc-primary;
}

/* purgecss end ignore */
