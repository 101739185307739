.c-link {
    $c: &;

    &--primary {
        &:after {
            @apply .bg-midc-primary;
        }
    }

    &--secondary {
        #{$c} {
            &__label,
            &__icon .c-icon {
                @apply .text-midc-primary;
            }
        }
    }

    &--tertiary {
        &:hover,
        &:active,
        &.hover {
            @apply .border-midc-primary;
        }
    }
}
