/* purgecss start ignore */
// @tailwind base;

// @tailwind components;

@import "midc/01-generics/_base.scss";

@import "midc/03-elements/_headings.scss";
@import "midc/04-components/_button.scss";
@import "midc/04-components/_cta.scss";
@import "midc/04-components/_form-field.scss";
@import "midc/04-components/_hero.scss";
@import "midc/04-components/_link.scss";
@import "midc/04-components/_nav.scss";
@import "midc/04-components/_partner.scss";
@import "midc/04-components/_presentation.scss";
@import "midc/04-components/_project.scss";
@import "midc/04-components/_slider.scss";
@import "midc/04-components/_text.scss";
/* purgecss end ignore */

// @tailwind utilities;
