.c-button {
    $c: &;

    &--primary {
        @apply .bg-midc-primary;
    }

    &--secondary {
        @apply .border-midc-primary;
    }
}
