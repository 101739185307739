// -----------------------------------------------------------------------------
// elements/heading
// -----------------------------------------------------------------------------

.h0,
h1,
h2,
h3,
h4,
h5,
h6 {
    strong {
        @apply .text-midc-primary;
    }
}
