.c-nav {
    $c: &;

    &--primary {
        #{$c} {
            &__item {
                &:after {
                    @apply .bg-midc-primary;
                }
            }

            &__item-inner {
                @screen lg {
                    &:hover,
                    &:active,
                    &.has-hover {
                        #{$c} {
                            &__label-text,
                            &__label-icon .c-icon {
                                @apply .text-midc-primary;
                            }
                        }
                    }
                }
            }

            &__sub-link {
                @screen lg {
                    &:after {
                        @apply .bg-midc-primary;
                    }

                    &:hover,
                    &:active {
                        #{$c} {
                            &__sub-label {
                                @apply .text-midc-primary;
                            }
                        }
                    }
                }
            }
        }
    }

    &--breadcrumb {
        #{$c} {
            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label-text {
                            @apply .text-midc-primary;
                        }
                    }
                }
            }
        }
    }
}
