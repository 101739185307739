.c-text {
    $c: &;

    a {
        &:after {
            @apply .bg-midc-primary;
        }
    }

    ul {
        li {
            &:before {
                @apply .bg-midc-primary;
            }
        }
    }

    ol {
        li {
            &::marker {
                @apply .text-midc-primary;
            }
        }
    }
}
