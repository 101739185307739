.c-presentation {
    $c: &;

    &__footer {
        &:before {
            @apply .bg-midc-primary;
        }
    }

    &:after {
        @apply .bg-midc-primary;
    }

    &.has-image {
        #{$c} {
            &__picture {
                &:after {
                    @apply .bg-secondary;
                }
            }
        }
    }
}
