.c-partner {
    $c: &;

    &:hover,
    &:active,
    &.is-active {
        &:after {
            @apply .border-midc-primary;
        }
    }
}
